import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import dayjs from 'dayjs'
import { Skeleton, Select, Tabs, Alert, Tag, Tooltip, Empty } from 'antd'
import { CheckmarkCircle24Filled } from '@fluentui/react-icons'
import { capitalize } from '@/utils/functions'
import getIconByUrl from '@/utils/getIconByUrl'
import FadeIn from '@/components/FadeIn'
import Blob from '@/components/Blob'
import HTMLBlock from '@/components/HTMLBlock'
import PlaygroundComments from '@/pages/MainPage/components/PlaygroundComments'
import {
  fetchVersionsAnalytics,
  setVersionsAnalytics,
  setPlaygroundVersion,
  setPlaygroundAnalyticsModalOpen,
} from '@/store/accounts/actions'
import { createPlaygroundPreview } from '@/store/playgrounds/actions'
import { fetchSessions, resetSessionsState } from '@/store/sessions/actions'
import { Modal } from './styles'

const PlaygroundDetailModal = () => {
  const dispatch = useDispatch()

  const { theme } = useSelector((state) => state.app)
  const { userProfile } = useSelector((state) => state.users)
  const {
    allUsers,
    currentAccount: account,
    playgroundsAnalytics,
    versionsAnalytics,
    currentVersion,
    isPlaygroundAnalyticsModalOpen,
    isPlaygroundVersionsLoading,
  } = useSelector((state) => state.accounts)
  const { items: sessions, isLoading: isSessionsLoading } = useSelector((state) => state.sessions)
  const { isPlaygroundPreviewLoading } = useSelector((state) => state.playgrounds)

  const userId = isPlaygroundAnalyticsModalOpen?.userId
  const playgroundId = isPlaygroundAnalyticsModalOpen?.playgroundId
  const playgroundCopyId = isPlaygroundAnalyticsModalOpen?.playgroundCopyId

  const isAccountOwner = userProfile?.accounts?.some((a) => a?.role === 'owner' && a?.id === account?.id)

  const [selectedTab, setSelectedTab] = useState('preview')
  const version = versionsAnalytics?.versions?.find((a) => a.created === currentVersion)
  const lastSubmittedAt = dayjs(versionsAnalytics?.versions?.[versionsAnalytics?.versions?.length - 1]?.created).format(
    'lll',
  )
  const lastSubmittedAtFrom = dayjs(
    versionsAnalytics?.versions?.[versionsAnalytics?.versions?.length - 1]?.created,
  ).from()

  const isSessionRunning = sessions?.some((s) => s.status === 'allocated')

  const userData = allUsers?.find((data) => data?.user?.id === userId)
  const playgroundData = playgroundsAnalytics?.results
    ?.find((p) => p.user_id === userId)
    ?.playgrounds?.find((p) => p.id === playgroundId)

  const allPlaygrounds = playgroundsAnalytics?.results?.map((userData) => userData?.playgrounds).flat()
  const playground = allPlaygrounds?.find((p) => p.id === playgroundId)

  const createdAt = playgroundData?.created ? dayjs(playgroundData?.created).format('lll') : '—'
  const createdAtFrom = playgroundData?.created && dayjs(playgroundData?.created).from()
  const lastModifiedAt = playgroundData?.last_modified ? dayjs(playgroundData?.last_modified).format('lll') : '—'
  const lastModifiedAtFrom = playgroundData?.last_modified && dayjs(playgroundData?.last_modified).from()
  // const timeInMin = parseInt(playgroundData?.lab_running_time / 60) || 0

  const [mainIcon, setMainIcon] = useState(null)
  const [previewHtml, setPreviewHtml] = useState('')
  const [isPreviewLoading, setIsPreviewLoading] = useState(false)

  const handleLoadPreviewHtml = async () => {
    setIsPreviewLoading(true)

    try {
      const previewUrl = version?.preview_html || versionsAnalytics?.last_preview_html

      if (previewUrl) {
        setSelectedTab('preview')

        const previewHtml = await axios({
          method: 'GET',
          url: previewUrl,
        })

        setPreviewHtml(previewHtml.data)
      } else {
        setPreviewHtml()
      }
    } finally {
      setIsPreviewLoading(false)
    }
  }

  const handleGeneratePreview = () => {
    dispatch(createPlaygroundPreview(playgroundCopyId, { commit: false }))
  }

  const tabItems = [
    {
      key: 'preview',
      label: 'Preview',
      children: (
        <>
          {isAccountOwner &&
            isSessionRunning &&
            playgroundData?.versions_count === 0 &&
            !isPlaygroundPreviewLoading && (
              <div className="generate-preview-block">
                <span className="info">
                  <Blob size="small" color="green" />
                  Running projects are subject to many changes. You might not have an updated view.
                </span>

                <a className="link" onClick={handleGeneratePreview}>
                  {versionsAnalytics?.last_preview_html ? 'Update preview' : 'Generate preview'}
                </a>
              </div>
            )}

          <div className="section playground-preview-section">
            {isPreviewLoading || isPlaygroundPreviewLoading ? (
              <div className="loading-content">
                {isPlaygroundPreviewLoading && (
                  <>
                    <h4 className="title">Generating preview...</h4>
                    <p className="description">This could take a few seconds...</p>
                  </>
                )}

                <Skeleton active title={false} paragraph={{ rows: 4 }} />
                <Skeleton active title={false} paragraph={{ rows: 4 }} />
              </div>
            ) : previewHtml ? (
              <HTMLBlock
                className={`playground-preview ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}
                content={previewHtml}
              />
            ) : playgroundData ? (
              <div className="no-preview-available">
                <h3 className="title">Preview not available</h3>

                <p className="description">
                  This project was started but doesn't have a preview yet as it's still being edited. Try again in a few
                  minutes.
                </p>

                {isAccountOwner && isSessionRunning && playgroundData?.versions_count === 0 && (
                  <a className="link" onClick={handleGeneratePreview}>
                    Generate preview
                  </a>
                )}
              </div>
            ) : (
              <div className="no-preview-available">
                <Empty className="no-content" description="This project has not been started yet." />
              </div>
            )}
          </div>
        </>
      ),
    },
    ...(playgroundData
      ? [
          {
            key: 'comments',
            label: <div className="tab-item">Timeline and Comments</div>,
            children: (
              <div className="section">
                <PlaygroundComments
                  user={userData?.user}
                  playgroundCopyId={playgroundCopyId}
                  versionsAnalytics={versionsAnalytics}
                />
              </div>
            ),
          },
        ]
      : []),
  ]

  const getStatusTag = () => {
    let tagStatus
    let tagText
    let tagTooltip

    if (!playgroundData) {
      tagText = 'not started'
    }

    if (playgroundData?.versions_count === 0) {
      tagStatus = 'not-submitted'
      tagText = 'not submitted'
      tagTooltip = `Started at ${createdAt} (${createdAtFrom})`
    }

    if (playgroundData?.versions_count > 0) {
      tagStatus = 'submitted'
      tagText = 'submitted'
      tagTooltip = `Last submitted at ${lastSubmittedAt} (${lastSubmittedAtFrom})`
    }

    return (
      <Tooltip title={tagTooltip}>
        <Tag className={`status-tag ${tagStatus}`}>
          {tagStatus === 'submitted' && <CheckmarkCircle24Filled />}
          {tagText}
        </Tag>
      </Tooltip>
    )
  }

  useEffect(() => {
    if (!playground) return

    getIconByUrl({
      iconUrl: playground?.data_sources?.[0]?.theme?.logo_url || 'ant-FileTextOutlined',
      className: 'main-icon',
      onReady: setMainIcon,
    })
  }, [playground])

  useEffect(() => {
    if (!currentVersion) return

    handleLoadPreviewHtml()
  }, [currentVersion])

  useEffect(() => {
    if (!versionsAnalytics || currentVersion) return

    dispatch(fetchSessions({ user_ids: userId, lab_ids: versionsAnalytics?.lab_id }))

    if (versionsAnalytics?.versions?.length) {
      dispatch(setPlaygroundVersion(versionsAnalytics?.versions?.slice(-1)?.[0]?.created))
      return
    }

    // check if there's manual-generated preview html
    handleLoadPreviewHtml()
  }, [versionsAnalytics])

  useEffect(() => {
    if (!isPlaygroundAnalyticsModalOpen) {
      dispatch(setVersionsAnalytics())
      dispatch(setPlaygroundVersion())
      dispatch(resetSessionsState())
      setSelectedTab('preview')
      setPreviewHtml(null)
      return
    }

    if (playgroundCopyId) {
      dispatch(fetchVersionsAnalytics(account?.id, { user_id: userId, playground_id: playgroundCopyId }))
    }
  }, [isPlaygroundAnalyticsModalOpen])

  return (
    <Modal
      className="playground-analytics-modal"
      open={!!isPlaygroundAnalyticsModalOpen}
      onCancel={() => dispatch(setPlaygroundAnalyticsModalOpen(false))}
      footer={null}
    >
      <div className="container">
        {isPlaygroundVersionsLoading ? (
          <div className="header is-loading">
            <Skeleton active />
            <br />
            <Skeleton active title={false} paragraph={{ rows: 4 }} />
            <Skeleton active />
            <Skeleton active title={false} paragraph={{ rows: 4 }} />
          </div>
        ) : (
          <>
            {versionsAnalytics?.new_version_available && (
              <Alert message={`Member is using an outdated version of this project.`} type="warning" showIcon banner />
            )}

            <div className="header">
              <div className="image-container">{mainIcon}</div>

              <div className="info">
                <div className="title-container">
                  {!isSessionsLoading && sessions?.length ? (
                    <Blob
                      color={isSessionRunning ? 'green' : 'red'}
                      text={isSessionRunning ? 'Project is running' : 'Project is not running'}
                      size="small"
                      addPulse={isSessionRunning}
                    />
                  ) : (
                    <Blob size="small" text="Project is not running" />
                  )}

                  <h3 className="title">{playground?.name}</h3>
                </div>

                <h5 className="subtitle">
                  {getStatusTag()}

                  {userData?.user?.first_name && userData?.user?.last_name && (
                    <div className="member-info">
                      <span>resolved by</span> {capitalize(userData?.user?.first_name)}{' '}
                      {capitalize(userData?.user?.last_name)}
                    </div>
                  )}
                </h5>
              </div>
            </div>

            {currentVersion && (
              <div className="select-container">
                <Select
                  className="attempt-select"
                  value={currentVersion}
                  placeholder="Select an attempt"
                  options={versionsAnalytics?.versions?.map((a, i) => ({
                    value: a.created,
                    label: `Submission ${i + 1} — started ${dayjs(a?.created).format('lll')}`,
                  }))}
                  onChange={(newVersion) => dispatch(setPlaygroundVersion(newVersion))}
                  virtual={false}
                />
              </div>
            )}

            <div className="content">
              <FadeIn>
                <Tabs className="tabs-container" items={tabItems} activeKey={selectedTab} onChange={setSelectedTab} />
              </FadeIn>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default PlaygroundDetailModal
